/** @jsx jsx */
import * as React from "react"
import { jsx, Container, Heading, Text } from "theme-ui"
import { TheLayout } from "../components/TheLayout"

const AboutPage = () => {
  return (
    <>
    <TheLayout>
    <Container sx={{my: [3, 4]}}>

      <title>About Page</title>

    <Heading variant="display" sx={{ fontWeight: 600,fontSize: [4,5,6]}}>About.</Heading>
    <Text sx={{variant: `text.bodytext`}}><p>For nearly two decades, smartassdesign has been providing creative solution at market prices.</p>

    <p>Sed sem libero, ultricies sed luctus eu, euismod quis dolor. Nam pretium gravida eros id vehicula. Phasellus ornare tincidunt tempor. Quisque et lectus nec risus hendrerit malesuada non in dui. Cras ac risus faucibus, vulputate nisl eu, dictum nisi. Etiam quis metus efficitur tortor imperdiet tincidunt vel sit amet eros. In in metus eget dui tempus efficitur eu vitae lectus. Duis sed auctor metus. Aliquam justo orci, lacinia non mi vel, ultrices imperdiet erat. Fusce posuere mi enim, at vehicula velit posuere non. Maecenas dolor sem, aliquam et rhoncus nec, aliquam ac metus.</p>
    
    </Text>
    
    </Container>
    </TheLayout>
    </>
  )
}

export default AboutPage
